import { FunctionComponent, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomQuestionAnswersByQuestionId } from "src/components/Form/QuestionForm/formik";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { formatNestedQuestionAsProps } from "src/services/formTemplate/customQuestion";
import { isNotNull } from "src/services/predicates";
import { Form } from "src/services/url/Parent";
import { ClonedQuestion, WithId } from "src/types/formTemplate";
import {
  useInsertPersonAnswerBankMutation,
  useSaveCustomQuestionAnswers,
} from "../api";
import { useCustomQuestionContext } from "../context/CustomQuestionContext";
import { mapBankAnswersToFormAnswers } from "../helpers";
import { AnswerBankEntryForm } from "./AnswerBankEntryForm";
import {
  formatEmptyInitialValues,
  formatInsertPersonAnswerBankPayload,
} from "./helpers";

export const CreateAnswerBankEntryForm: FunctionComponent = () => {
  const {
    organization,
    customQuestion,
    customQuestionType,
    formId,
    personId,
    sourceIdCloneIdMapping,
    setShowAnswerBank,
  } = useCustomQuestionContext();

  const { step = "" } = useParams();
  const navigate = useNavigate();

  const insertPersonAnswerBankEntry = useInsertPersonAnswerBankMutation();
  const upsertCustomQuestionAnswers = useSaveCustomQuestionAnswers();

  const toast = useAvelaToast({
    position: "bottom",
    containerStyle: { marginBottom: 16 },
    isClosable: true,
  });

  const initialValues = formatEmptyInitialValues(
    customQuestionType.custom_question_type_fields.map(
      (field) => field.question_id
    )
  );

  const fieldQuestions: ClonedQuestion<WithId>[] = useMemo(() => {
    return customQuestionType.custom_question_type_fields
      .map((field) => {
        return formatNestedQuestionAsProps(field.question);
      })
      .filter(isNotNull);
  }, [customQuestionType.custom_question_type_fields]);

  const onSubmitHandler = useCallback(
    async (values: CustomQuestionAnswersByQuestionId) => {
      try {
        const result = await insertPersonAnswerBankEntry({
          variables: {
            entry: formatInsertPersonAnswerBankPayload({
              answersByQuestionId: values,
              customQuestionTypeId: customQuestionType.id,
              fieldQuestions,
              personId,
            }),
          },
        });
        const answerBankId = result.data?.insert_person_answer_bank_one?.id;
        if (!answerBankId) {
          throw new Error("Failed to insert answer bank entry");
        }
        await upsertCustomQuestionAnswers({
          formId,
          customQuestion,
          answersByQuestionId: mapBankAnswersToFormAnswers(
            values,
            sourceIdCloneIdMapping
          ),
          answerBankId,
        });
        toast({ title: `${customQuestion.question} saved` });
        setShowAnswerBank(true);
        navigate(Form.edit(organization, formId, parseInt(step, 10)), {
          state: { questionId: customQuestion.id },
        });
      } catch (error) {
        console.error(error);
        toast({
          title: `Error saving ${customQuestion.question}`,
          description:
            "Please try again later or report the problem to our support team.",
          status: "error",
        });
      }
    },
    [
      insertPersonAnswerBankEntry,
      customQuestionType.id,
      fieldQuestions,
      personId,
      upsertCustomQuestionAnswers,
      formId,
      customQuestion,
      sourceIdCloneIdMapping,
      toast,
      setShowAnswerBank,
      navigate,
      organization,
      step,
    ]
  );

  return (
    <AnswerBankEntryForm
      initialValues={initialValues}
      onSubmitHandler={onSubmitHandler}
    />
  );
};

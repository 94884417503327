import { UseOrganizationType } from "src/hooks/useOrganization";

// TODO: Remove and replace with orgConfig
const orgsThatAcceptsUnverifiedComms = [
  "enrollwcc",
  "oaklandenrolls",
  "aimsk12",
  "efcps",
  "enrollbuffalocharters",
  "schoolappkc",
  "garlandisd",
  "invictus",
];

export const checkUnverifiedCommsPerOrg = (
  organization: UseOrganizationType,
  flagEnabled: boolean
) => {
  const organizationPath = organization.map((org) => org.path).withDefault("");
  const orgAcceptsUnverfiedComms = organizationPath
    ? orgsThatAcceptsUnverifiedComms.includes(organizationPath)
    : false;

  return flagEnabled && orgAcceptsUnverfiedComms;
};
